/*  */

import { QRCode } from "react-qrcode-logo";
import ModalContext from "contexts/ModalContext";
import { setProfile } from "features/ProfileSlice";
import Menu from "pages/menuMain/menu";
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { GetCustomerData } from "services/Api/Module/Customer";
import { useNavigate } from "react-router-dom";
import { monthNames } from "../../utility/date";
import ModalQrCode from "./modalQr";
import ModalHeartEmpty from "./modalHeartEmpty";

// import QRCode from "react-qr-code";
import $ from "jquery";
import { koalaImg } from "utility/koalaImg";
import moment from "moment";

import GetCurrentPoint from "./GetCurrentPoint";
import Banners from "pages/centerComponent/Banners";

const Profile = () => {
  const [openQr, setOpenQr] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateProfile = useSelector((state) => state.profile.dataUser);
  const stateCurrentPoint = useSelector((state) => state.currentPoint);
  const newCurrentPoint = stateCurrentPoint.currentPoint;
  const newCurrentHeart = stateCurrentPoint.currentHeart;
  const isSchedualGame = stateCurrentPoint.isSchedualGame == 1 ? true : false;
  const isSchedualSticker =
    stateCurrentPoint.isSchedualSticker == 1 ? true : false;
  const koalaImage = koalaImg;

  const { mAlert } = useContext(ModalContext);

  useEffect(() => {
    $(".btnCopy").on("click", function () {
      var textToCopy = $(this).text();

      // Create a temporary input element
      var tempInput = $("<input>");
      $("body").append(tempInput);
      tempInput.val(textToCopy).select();

      // Execute the copy command
      document.execCommand("copy");

      // Remove the temporary input
      tempInput.remove();

      $("body").addClass("copy");
      setTimeout(() => {
        $("body").removeClass("copy");
      }, 1000);
    });

    // Don't forget to clean up any resources when the component unmounts
    return () => {
      $(".btnCopy").off("click");
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  // dispatch(setProfile(_return.result));
  const fetchCustomerProfile = async (id) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await GetCustomerData({ id: id });

      if (data.resultCode === "20000") {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        mAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
          onExit: () => {},
        });
      }
    } catch (e) {
      console.log("ERR :", e);
    }
    return _return;
  };

  useEffect(() => {
    (async () => {
      if (stateProfile.customerId) {
        // const cusId = /*stateProfile.customerId*/10100;
        const cusId = stateProfile.customerId;
        const custormerProfile = await fetchCustomerProfile(cusId);
        const birthDate = prepareBirthDate(custormerProfile.result.birthDate);
        dispatch(
          setProfile({
            ...custormerProfile.result,
            customerId: custormerProfile.result.id,
            birthDateConvert: birthDate,
          })
        );
      }
    })();
  }, []);

  const openListenerTips = (e) => {
    if (e?.target?.id === "iconInfo") {
      document.getElementById("info").classList.remove("hide");
    }
  };

  const closeListenerTips = (e) => {
    if (e?.target?.className === "closeBtn") {
      document.getElementById("info").classList.add("hide");
    }
  };
  const handleFromChild = (data) => {
    setOpenPopup(data);
  };

  const {
    pProfileImgUrl,
    firstName,
    memberId,
    birthDateConvert,
    lineImageUrl,
  } = stateProfile;

  const prepareBirthDate = (birthDate) => {
    const date = birthDate.split("-");
    const monthName = monthNames.find((item) => item.id == date[1]);
    date[1] = monthName.name;
    date[0] = Number(date[0]) + 543;
    const dateReverse = date.reverse().join(" ");

    return dateReverse;
  };

  useEffect(() => {
    if (!stateProfile.isRegisSbSystem) {
      // CASE เคยสมัครแร้วใน ระบบเก่า ต้องไปหน้าสมัคร
      navigate("/");
    }
  }, [stateProfile]);
  return (
    <div>
      <GetCurrentPoint />
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pageProfile">
        {/* <!-- Content Page --> */}
        <main>
          <div className="wrapContent">
            <section id="pProfileMain">
              <div className="bProfile">
                <div className="bGroup">
                  <div className="bMemberDetails">
                    <div className="bDisplay">
                      <div
                        className="bPic"
                        style={{
                          backgroundImage: lineImageUrl
                            ? `url(${lineImageUrl})`
                            : `url(${pProfileImgUrl})`,
                        }}
                      ></div>
                    </div>
                    <div className="bDetails">
                      <h1>{firstName}</h1>
                      <p data-icon="birthday">{birthDateConvert}</p>
                      <p className="bMemberID">
                        ID : <a className="btnCopy">{memberId}</a>
                      </p>
                    </div>
                  </div>
                  <div className="bQR">
                    <a id="btnModalQR" onClick={() => setOpenQr(true)}>
                      <div className="bPic">
                        {memberId ? (
                          // <QRCode  size={70} value={memberId} viewBox={`0 0 70 70`}/>
                          <QRCode
                            value={memberId}
                            size={70}
                            logoImage={koalaImage}
                            logoHeight={20}
                            logoWidth={20}
                            logoOpacity={1}
                            enableCORS={true}
                            qrStyle="squares"
                            eyeRadius={0}
                            id={"QR"}
                          />
                        ) : (
                          <img src="https://dummyimage.com/200x200" alt="" />
                        )}
                      </div>
                      <p>ชวนเพื่อนสแกน QR</p>
                    </a>
                  </div>
                </div>
                <div className="bBtn">
                  <button
                    className="btn"
                    onClick={() => navigate("/profile-view")}
                  >
                    ดูโปรไฟล์
                  </button>
                </div>
              </div>

              <div className="bPoint">
                <div className="bTypePoint">
                  <div className="bPointGroup">
                    <p>คุณมี Point</p>
                    <div className="bTotalPoint">
                      <span>{newCurrentPoint ?? "0"}</span>
                    </div>
                  </div>
                  {isSchedualGame && (
                    <div className="bPointGroup">
                      <div className="tNote">
                        <p>คุณมี Heart</p>
                        <a>
                          <img
                            id="iconInfo"
                            src="assets/img/ci/phase2/ico-description.svg"
                            onClick={openListenerTips}
                          />
                        </a>
                      </div>
                      <div className="bTotalPointHeart">
                        <span>{newCurrentHeart ?? "0"}</span>
                      </div>

                      {/* tooltip  className="bNotice hide" */}

                      <div className="bNotice hide" id="info">
                        <div className="tHead">
                          <div
                            className="closeBtn"
                            onClick={closeListenerTips}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <p>
                  หมดอายุ 31 ธันวาคม {Number(moment().format("YYYY")) + 543}
                </p>
              </div>
              <div className="bShortcut">
                <div className="bGroup">
                  <a className="reward" onClick={() => navigate("/reward")}>
                    Rewards
                  </a>
                  <a
                    className="luckydraw"
                    onClick={() => navigate("/luckydraw")}
                  >
                    Lucky draw
                  </a>
                  {/* {isSchedualSticker && (
                    <a
                      className="collection"
                      onClick={() => navigate("/character-collection")}
                    >
                      Character <br />
                      Collection
                    </a>
                  )} */}

                  <a
                    className="history"
                    onClick={() => navigate("/history-collect-point")}
                  >
                    History
                  </a>
                </div>
              </div>
            </section>
            <section id="pEvent">
              <Banners
                currentPage="Member"
                sendDataToParent={handleFromChild}
              />
            </section>
            <Menu />
            <ModalQrCode
              isOpen={openQr}
              onClose={() => setOpenQr(false)}
              memberId={memberId}
              pProfileImgUrl={pProfileImgUrl}
              lineImageUrl={lineImageUrl}
              Name={firstName}
            />

            <ModalHeartEmpty
              isOpen={openPopup}
              onClose={() => setOpenPopup(false)}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Profile;
